* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.main-container > img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  z-index: -1;
}

input:-internal-autofill-selected {
  background: transparent !important;
}
.MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: gray !important;
}

/* Start loader */
@keyframes tp-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tp-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999999;
}

.tp-spinner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  height: 100%;
}
.tp-spinner span {
  position: relative;
  top: 120px;
  left: 0;
  font-size: 18px;
  font-weight: 500;
}
.tp-spinner:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #3b97d3;
  border-color: #3b97d3 transparent #3b97d3 transparent;
  animation: tp-spinner 1.2s linear infinite;
}
@media screen and (max-width: 767px) {
  .tp-spinner {
    left: 0 !important;
  }
}
/* End loader */
